<template>
  <div class="api-call-container">
    <ul>
      <li v-for="(item, index) in itemList" :key="index">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [],
      apiCallInterval: 1000, // 2 seconds interval
      isLoading: true,
    };
  },
  methods: {
    async makeApiCall(item) {
      try {
        // Simulating an API call with a timeout
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.itemList.push(`Item: ${item}`);
      } catch (error) {
        console.error('Error making API call:', error);
      }
    },
    async iterateAndCallAPI() {
      for (const item of ['item1', 'item2', 'item3', 'item4', 'item5', 'item6']) {
        await this.makeApiCall(item);
        await this.delay(this.apiCallInterval);
      }
      this.isLoading = false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
  created() {
    this.iterateAndCallAPI();
  },
};
</script>

<style scoped>
.api-call-container {
  max-width: 300px;
  margin: auto;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
